
import {Component, VueComponentBase} from "vue3-component-base";
import ZEcharts from "@/components/core/zechart.vue";
import {EChartsOption} from "echarts";
import ds from "@/core/data"
@Component({name: "LeftBottom", components: {ZEcharts}})
export default class LeftBottom extends VueComponentBase {
  eOpt: EChartsOption = {
    tooltip:{},
    series: [
      {
        name: '纳税排行榜',
        type: 'treemap',
        width:'100%',
        height:'100%',
        itemStyle: {
          borderColor: '#fff'
        },
        breadcrumb:undefined,
        data: ds.parkLeftBottom()
      }
    ]
  }
  timeHandle=0;
  current=0;
  mounted() {
    this.timeHandle = setInterval(() => {
      //@ts-ignore
      let len = this.eOpt.series[0].data.length;
      if (len < 1) return;
      if (this.current < len - 1) {
        this.current = this.current + 1;
      } else {
        this.current = 0;
      }
      //@ts-ignore
      this.$refs["zchart"].dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: this.current
      })
    }, 3000)
  }
  beforeUnmount() {
    if (this.timeHandle > 0) {
      clearInterval(this.timeHandle)
    }
  }
}
