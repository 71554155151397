
import type {EChartsOption} from 'echarts';
import {init as echartInit} from 'echarts';
import {addListener,removeListener} from "resize-detector";
import { capitalize, h } from 'vue';
import { Component, Inreactive, Prop, VueComponentBase, Watch } from 'vue3-component-base';

const EchartsEvents = [
  'click',
  'dblclick',
  'mousedown',
  'mouseup',
  'mouseover',
  'mouseout',
  'globalout',
  'legendselectchanged',
  'legendselected',
  'legendunselected',
  'legendscroll',
  'datazoom',
  'datarangeselected',
  'timelinechanged',
  'timelineplaychanged',
  'restore',
  'dataviewchanged',
  'magictypechanged',
  'geoselectchanged',
  'geoselected',
  'geounselected',
  'pieselectchanged',
  'pieselected',
  'pieunselected',
  'mapselectchanged',
  'mapselected',
  'mapunselected',
  'axisareaselected',
  'focusnodeadjacency',
  'unfocusnodeadjacency',
  'brush',
  'brushselected',
];

type EchartsInstance = ReturnType<typeof echartInit>;

@Component({
  name: 'ZEcharts',
  emits: EchartsEvents,
})
export default class ZEcharts extends VueComponentBase {
  @Prop() readonly option: EChartsOption;
  @Prop({ default: 'soonbox' }) readonly theme: string;
  @Prop() readonly groupId: string;
  @Prop({
    default: () => ({
      text: '努力加载中',
      color: '#c23531',
      textColor: '#489CFF',
      spinnerRadius: 6,
      lineWidth: 2,
      maskColor: 'rgba(0, 0, 0, 0.1)',
      zlevel: 0,
    }),
  }) readonly loadingOption: Record<string, any>;
  @Prop() readonly initCfg: Parameters<typeof echartInit>[2];
  @Inreactive resizing: boolean;
  @Inreactive chart: EchartsInstance;

  mounted() {
    this.refreshChart();
    //@ts-ignore
    addListener(this.$refs["m-chart"],()=>{
      this.chart.resize();
    })

  }

  beforeUnmount() {

    if (this.chart) {
      this.chart.dispose();
      //@ts-ignore
      this.chart = undefined;
    }
    //@ts-ignore
    removeListener(this.$refs["m-chart"])
  }

  @Watch('option')
  refreshOption() {
    if (!this.chart) return;
    if (this.option && Object.keys(this.option).some(x => /^[a-z]/.test(x))) {
      this.chart.setOption(this.option, true);
      if (this.$el.clientHeight) this.chart.resize();
      this.chart.hideLoading();
    } else {
      this.chart.showLoading('default', this.loadingOption);
    }
  }

  @Watch('theme')
  refreshChart() {
    if (this.chart) {
      this.chart.dispose();
      //@ts-ignore
      this.chart = undefined;
    }
    //@ts-ignore
    const chart = echartInit(this.$refs['m-chart'], this.theme, this.initCfg);
    chart.group = this.groupId;
    this.chart = chart;
    this.refreshOption();

    EchartsEvents.forEach(x => {
      const eventName = 'on' + capitalize(x);
      //@ts-ignore
      if (typeof this.$.vnode.props[eventName] === 'function') {
        chart.on(x, this.$emit.bind(this, x));
      }
    });
  }

  setOption(...args: Parameters<EchartsInstance['setOption']>) {
    return this.chart.setOption(...args);
  }

  dispatchAction(...args: Parameters<EchartsInstance['dispatchAction']>) {
    return this.chart.dispatchAction(...args);
  }
}
