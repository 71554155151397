
import {defineComponent} from "vue";
export default defineComponent({
name: "CenterBottom",
data(){
return {

}},
components:{},
methods:{}
})
