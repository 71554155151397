<style lang="less" scoped>
  .ent-card{
    background: url("../../assets/images/ent-item-bg.png");
    background-size: cover;
    width:320px;
    height:128px;
    .title{
    .name{
      font-size: 24px;
      color:white;
      font-weight: bold;
      margin-top:10px;
      margin-left: 30px;
    }
      .rate{
        margin-right: 30px;
        margin-top: 15px;
        font-size: 20px;
        color:#019b51
      }
    }
    .content{
      font-size: 48px;
      margin-top: -5px;
      color:#18bfd8;
    }
  }
</style>
<template>
  <div class="ent-card">
    <div class="title zfx zfx-row zfx-jc-between">
      <div class="name">{{name}}</div>
      <div class="rate">{{rate}}%</div>
    </div>
    <div class="content">{{val}}</div>
  </div>
</template>

<script lang="ts">
import {Component,Prop,VueComponentBase} from "vue3-component-base";
@Component({name:"entCard"})
export default class EntCard extends VueComponentBase{
 @Prop({type:String,required:true})
 name:string
  @Prop({type:Number,required:true})
  val:number
  @Prop({type:Number,required:true})
  rate:number
}
</script>
