<style lang="less" scoped>
.con {
  transform-style: preserve-3d;
  margin-top: 50px;
  margin-left: 40px;
  background:url("../assets/images/honor-bg.png") center no-repeat;
  width: 1847px;
  height: 800px;
  background-size: 1847px 800px;
  .card1{
    position: absolute;
    left:220px;
    top:60px;
  }
  .card2{
    position: absolute;
    left:380px;
    top:500px;
  }
  .card3{
    position: absolute;
    left:580px;
    top:0px;
  }
  .card4{
    position: absolute;
    left:740px;
    top:560px;
  }
  .card4{
    position: absolute;
    left:740px;
    top:560px;
  }
  .card5{
    position: absolute;
    left:1040px;
    top:-40px;
  }
  .card6{
    position: absolute;
    left:1200px;
    top:590px;
  }
  .card7{
    position: absolute;
    left:1400px;
    top:100px;
  }
  .card8{
    position: absolute;
    left:1500px;
    top:460px;
  }
}
</style>
<template>
  <page-container title="中豹荣誉">
    <div class="con">
      <hornor-card class="card1" title="2020年5月"
                   image="assets/images/hornor/1.png"
                   desc="中豹数字产业园与华侨大学共同建立龙岩直播基地。">
      </hornor-card>
      <hornor-card class="card2" title="2020年5月"
                   image="assets/images/hornor/2.png"
                   desc="杭州市商务局认定魔筷直播基地为电商直播重点培育平台。">
      </hornor-card>
      <hornor-card class="card3" title="2020年7月"
                   image="assets/images/hornor/3.png"
                   desc="中豹数字产业园荣获龙岩市首家“直播电商示范企业”荣誉称号。">
      </hornor-card>
      <hornor-card class="card4" title="2020年8月"
                   image="assets/images/hornor/4.png"
                   desc="清华大学龙岩校友会在中豹数字产业园正式揭牌成立。">
      </hornor-card>
      <hornor-card class="card5" title="2020年9月"
                   image="assets/images/hornor/5.png"
                   desc="中豹数字产业园“青年创业人才驿站”开始对外提供服务。">
      </hornor-card>
      <hornor-card class="card6" title="2020年10月"
                   image="assets/images/hornor/6.png"
                   desc="中豹数字产业园荣获”龙岩市信息协会“副会长单位">
      </hornor-card>
      <hornor-card class="card7" title="2020年11月"
                   image="assets/images/hornor/7.png"
                   desc="龙岩市大数据和政务管理局与中豹数字产业园共同成立”龙岩市区块链产业创新发展基地“">
      </hornor-card>
      <hornor-card class="card8" title="2020年11月"
                   image="assets/images/hornor/8.png"
                   desc="龙岩市大数据和政务管理局”龙岩市数字经济创新创业大赛“服务中心在中豹数字产业园成立。">
      </hornor-card>
    </div>
  </page-container>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import PageContainer from "@/components/core/pageContainer.vue";
import HornorCard from "@/components/hornor/HornorCard.vue";

export default defineComponent({
  name: "honur",
  data() {
    return {}
  },
  components: {HornorCard, PageContainer},
  methods: {}
})
</script>
