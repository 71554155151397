import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/zzz.css"
import * as echart from 'echarts';
import echartConfig from './config/echart-config';
import {registerTheme} from "@antv/g2";
import G2Config from "./config/g2-config"
import "swiper/swiper-bundle.min.css";
echart.registerTheme("soonbox",echartConfig);
registerTheme("zb",G2Config);
createApp(App).use(store).use(router).mount('#app')
