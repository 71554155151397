
import {Component, VueComponentBase} from "vue3-component-base";
import ds from "@/core/data"
import PageContainer from "@/components/core/pageContainer.vue";
import {SwiperOptions} from "swiper";
import EntCard from "@/components/core/entCard.vue";
const dRow=ds.entList();
@Component({
  name: "EntData",
  components: {EntCard, PageContainer}
})
export default class EntData extends VueComponentBase {
  rows=dRow
  curIndex= 0

   get cRow(){
    console.warn(this.rows.length)
    return this.rows[this.curIndex];
  }

  changeIdx(idx: number) {
    this.curIndex = idx;
  }

}
