export default {
    "color": [
        "#5b8ff9",
        "#61ddaa",
        "#65789b",
        "#f6bd16",
        "#7262fd",
        "#78d3f8",
        "#9661bc",
        "#f6903d",
        "#008685",
        "#f08bb4"
    ],
    "backgroundColor": "rgba(255,255,255,0.0)",
    "textStyle": {
    },
    "title": {
        "textStyle": {
            "color": "rgba(238,238,238,0.54)"
        },
        "subtextStyle": {
            "color": "rgba(170,170,170,0.59)"
        }
    },
    "line": {
        "itemStyle": {
            "borderWidth": 1
        },
        "lineStyle": {
            "width": 2
        },
        "symbolSize": 4,
        "symbol": "emptyDiamond",
        "smooth": false
    },
    "radar": {
        "itemStyle": {
            "borderWidth": 1
        },
        "lineStyle": {
            "width": 2
        },
        "symbolSize": 4,
        "symbol": "emptyDiamond",
        "smooth": false
    },
    "bar": {
        "itemStyle": {
            "barBorderWidth": 0,
            "barBorderColor": "#ccc"
        }
    },
    "pie": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        },
        label:{
            textStyle:{
                color:"#5b8ff9"
            },
        }
    },
    "scatter": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "boxplot": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "parallel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "sankey": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "funnel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "gauge": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "candlestick": {
        "itemStyle": {
            "color": "#c84d32",
            "color0": "#5b8ff9",
            "borderColor": "#c84d32",
            "borderColor0": "#5b8ff9",
            "borderWidth": 1
        }
    },
    "graph": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        },
        "lineStyle": {
            "width": "1",
            "color": "#9ac5ff"
        },
        "symbolSize": 4,
        "symbol": "emptyDiamond",
        "smooth": false,
        "color": [
            "#5b8ff9",
            "#61ddaa",
            "#65789b",
            "#f6bd16",
            "#7262fd",
            "#78d3f8",
            "#9661bc",
            "#f6903d",
            "#008685",
            "#f08bb4"
        ],
        "label": {
            "color": "#ffffff"
        }
    },
    "map": {
        "itemStyle": {
            "normal": {
                "areaColor": "#eee",
                "borderColor": "#444",
                "borderWidth": 0.5
            },
            "emphasis": {
                "areaColor": "rgba(255,215,0,0.8)",
                "borderColor": "#444",
                "borderWidth": 1
            }
        },
        "label": {
            "normal": {
                "textStyle": {
                    "color": "#000"
                }
            },
            "emphasis": {
                "textStyle": {
                    "color": "rgb(100,0,0)"
                }
            }
        }
    },
    "geo": {
        "itemStyle": {
            "normal": {
                "areaColor": "#eee",
                "borderColor": "#444",
                "borderWidth": 0.5
            },
            "emphasis": {
                "areaColor": "rgba(255,215,0,0.8)",
                "borderColor": "#444",
                "borderWidth": 1
            }
        },
        "label": {
            "normal": {
                "textStyle": {
                    "color": "#000"
                }
            },
            "emphasis": {
                "textStyle": {
                    "color": "rgb(100,0,0)"
                }
            }
        }
    },
    "categoryAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "rgba(90,103,230,0.2)"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "rgba(177,186,251,0.2)"
            }
        },
        "axisLabel": {
            "show": true,
            "textStyle": {
                "color": "rgba(167,200,241,0.47)"
            }
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "rgba(190,200,252,0.18)"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        }
    },
    "valueAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "rgba(90,103,230,0.2)"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "rgba(177,186,251,0.2)"
            }
        },
        "axisLabel": {
            "show": true,
            "textStyle": {
                "color": "rgba(167,200,241,0.47)"
            }
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "rgba(190,200,252,0.18)"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        }
    },
    "logAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "rgba(90,103,230,0.2)"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "rgba(177,186,251,0.2)"
            }
        },
        "axisLabel": {
            "show": true,
            "textStyle": {
                "color": "rgba(167,200,241,0.47)"
            }
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "rgba(190,200,252,0.18)"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        }
    },
    "timeAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "rgba(90,103,230,0.2)"
            }
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "rgba(177,186,251,0.2)"
            }
        },
        "axisLabel": {
            "show": true,
            "textStyle": {
                "color": "rgba(167,200,241,0.47)"
            }
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "rgba(190,200,252,0.18)"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        }
    },
    "toolbox": {
        "iconStyle": {
            "normal": {
                "borderColor": "#999999"
            },
            "emphasis": {
                "borderColor": "#666666"
            }
        }
    },
    "legend": {
        "textStyle": {
            "color": "#93adf8"
        }
    },
    "tooltip": {
        "axisPointer": {
            "lineStyle": {
                "color": "#0333d9",
                "width": "2"
            },
            "crossStyle": {
                "color": "#0333d9",
                "width": "2"
            }
        }
    },
    "timeline": {
        "lineStyle": {
            "color": "#b8e1ff",
            "width": 1
        },
        "itemStyle": {
            "normal": {
                "color": "#7daaff",
                "borderWidth": "0"
            },
            "emphasis": {
                "color": "#3d76dd"
            }
        },
        "controlStyle": {
            "normal": {
                "color": "#b8e1ff",
                "borderColor": "#b8e1ff",
                "borderWidth": 0.5
            },
            "emphasis": {
                "color": "#b8e1ff",
                "borderColor": "#b8e1ff",
                "borderWidth": 0.5
            }
        },
        "checkpointStyle": {
            "color": "#0047a5",
            "borderColor": "#3d76dd"
        },
        "label": {
            "normal": {
                "textStyle": {
                    "color": "#b8e1ff"
                }
            },
            "emphasis": {
                "textStyle": {
                    "color": "#b8e1ff"
                }
            }
        }
    },
    "visualMap": {
        "color": [
            "#001d70",
            "#0047a5",
            "#3d76dd",
            "#7daaff",
            "#b8e1ff"
        ]
    },
    "dataZoom": {
        "backgroundColor": "rgba(47,69,84,0)",
        "dataBackgroundColor": "rgba(255,255,255,0.3)",
        "fillerColor": "rgba(167,183,204,0.4)",
        "handleColor": "#a7b7cc",
        "handleSize": "100%",
        "textStyle": {
            "color": "#eeeeee"
        }
    },
    "markPoint": {
        "label": {
            "color": "#ffffff"
        },
        "emphasis": {
            "label": {
                "color": "#ffffff"
            }
        }
    }
}
