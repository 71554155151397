<style lang="less" scoped>
  .title{
    color:#ffffff;
    font-weight: bold;
    font-size: 40px;
    margin-top: -380px;
    line-height: 60px;
  }
</style>
<template>
  <div class="title">与您共享 智汇未来</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
export default defineComponent({
name: "CenterBottom",
data(){
return {

}},
components:{},
methods:{}
})
</script>
