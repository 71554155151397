<style lang="less" scoped>
    .con{
      height:210px;
      width:600px;
      margin-left: -40px;
      padding-top: 10px;
      .item{
        margin-right: 25px;
        .title{
          font-size: 28px;
          color: #ffffff;
        }
        .money{
          font-size: 36px;
          color:#ffc000;
        }
        .rate-title{
          color:#ffffff;
          line-height: 27px;
          font-size: 18px;
        }
        .rate{
          color:#04beb2;
          line-height: 27px;
          font-size: 18px;
        }
      }
    }
</style>
<template>
  <div class="con zfx zfx-row">
    <div class="item zfx zfx-col">
      <div class="title">销售总额</div>
      <div class="money">{{ total.sale.num }}</div>
      <div class="rate-title">增长率</div>
      <div class="rate">{{ total.sale.rate }}%</div>
    </div>
    <div class="item zfx zfx-col">
      <div class="title">纳税总额</div>
      <div class="money">{{ total.tax.num }}</div>
      <div class="rate-title">增长率</div>
      <div class="rate">{{total.tax.rate}}%</div>
    </div>
    <div class="item zfx zfx-col">
      <div class="title">就业人数</div>
      <div class="money">{{total.worker.num}}</div>
      <div class="rate-title">增长率</div>
      <div class="rate">{{ total.worker.rate }}%</div>
    </div>
    <div class="item zfx zfx-col">
      <div class="title">客户总量</div>
      <div class="money">{{ total.custom.num }}</div>
      <div class="rate-title">增长率</div>
      <div class="rate">{{ total.custom.rate }}%</div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ds from "@/core/data"
const dt=ds.parkCenterTop();
export default defineComponent({
name: "CenterTop",
data(){
return {
  total:dt
}},
components:{},
methods:{}
})
</script>
