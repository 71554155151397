
import {defineComponent} from "vue";
import LeftTop from "@/components/park/LeftTop.vue";
import LeftCenter from "@/components/park/LeftCenter.vue";
import LeftBottom from "@/components/park/LeftBottom.vue";
import CenterTop from "@/components/park/CenterTop.vue";
import CenterCenter from "@/components/park/CenterCenter.vue";
import CenterBottom from "@/components/park/CenterBottom.vue";
import RightTop from "@/components/park/RightTop.vue";
import RightCenter from "@/components/park/RightCenter.vue";
import RightBottom from "@/components/park/RightBottom.vue";
import ZTitle from "@/components/core/ztitile.vue";
import PageContainer from "@/components/core/pageContainer.vue";

export default defineComponent({
  name: "ParkData",
  data() {
    return {}
  },
  components: {
    PageContainer,
    ZTitle,
    RightBottom,
    RightCenter, RightTop, CenterBottom, CenterCenter, CenterTop, LeftBottom, LeftCenter, LeftTop},
  methods: {}
})
