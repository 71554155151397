<style lang="less" scoped>
.con {
  background: url("../../assets/images/park-right-center-bg.png");
  background-size: cover;
  width: 648px;
  height: 200px;
  margin-left: 30px;
  .ring {
    margin-top: 12px;
    margin-left:25px;
    width:175px;
    height:175px;
  }
  .text-con{
    margin-left: -145px;
    margin-top: 40px;
    .rate{
      margin-top: 10px;
      font-size: 48px;
      color: #ffffff;
      line-height: 72px;
      width:115px;
      font-weight: bold;
    }
    .title{
      line-height: 16px;
      color:#ffffff;
      width:115px;
      font-size: 16px;
      text-align: center;
    }
  }
  .data{
    margin-left: 0px;
    margin-top: 65px;
    .item{
      width:110px;
      color:#ffffff;
      .title{
        font-size: 18px;
        line-height: 24px;
        color: #a9b0b9;
      }
      .num{
        font-size:28px;
        line-height: 40px;
        font-weight: bold;
      }
      .rate{
        font-size: 18px;
        color:#04bdb9;
      }
    }
  }
}
</style>
<template>
  <div class="con zfx zfx-row">
    <div class="data zfx zfx-row">
      <div class="item">
        <div class="title">销售总额</div>
        <div class="num">{{ eData.sale.num }}</div>
        <div class="rate">{{eData.sale.rate}}%</div>
      </div>
      <div class="item">
        <div class="title">纳税总额</div>
        <div class="num">{{eData.tax.num}}</div>
        <div class="rate">{{eData.tax.rate}}%</div>
      </div>
      <div class="item">
        <div class="title">就业人数</div>
        <div class="num">{{eData.worker.num}}</div>
        <div class="rate">{{eData.worker.rate}}%</div>
      </div>
      <div class="item">
        <div class="title">客户数量</div>
        <div class="num">{{eData.custom.num}}</div>
        <div class="rate">{{eData.custom.rate}}%</div>
      </div>
    </div>
    <div class="pie">
      <z-echarts class="ring" :option="eOpt"></z-echarts>
    </div>
    <div class="text-con">
      <div class="rate">{{eData.percent[0]}}</div>
      <div class="title">本年达成率</div>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, VueComponentBase} from "vue3-component-base";
import ZEcharts from "@/components/core/zechart.vue";
import {EChartsOption} from "echarts";
import ds from "@/core/data"
@Component({
  name: "RightCenter",
  components: {ZEcharts}
})
export default class RightCenter extends VueComponentBase {
  eData=ds.parkRightCenter();
  eOpt: EChartsOption = {
    series: [
      {
        color:["#5ad8a6","#002060"],
        labelLine:undefined,
        type: "pie",
        radius: ['65%', '95%'],
        data: this.eData.percent
      }
    ]
  }
}
</script>
