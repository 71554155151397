
import {defineComponent} from "vue";
import ZEcharts from "@/components/core/zechart.vue";
import {EChartsOption} from "echarts";
import {Component, VueComponentBase} from "vue3-component-base";
import ds from "@/core/data"
@Component({
  name: 'RightTop',
  components: {ZEcharts}
})
export default class RightTop extends VueComponentBase {
  eOpt: EChartsOption = {
    tooltip: {
      trigger: "item"
    },
    dataset: {
      source:ds.parkRightTop()
    },
    legend:{
      right:10,
      orient:'vertical'
    },
    series: [
      {
        type: "pie",
        radius: '100%',
        left:-160,

        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        label:{
          borderWidth:0,
          color:"#5b8ff9",
          textBorderWidth:-1,
          alignTo:"labelLine",
          bleedMargin:20,
          borderColor:"#5b8ff9"
        }
      }
    ]
  }
  timeHandle=0;
  current=0;
  mounted() {
    this.timeHandle = setInterval(() => {
      //@ts-ignore
      let len = this.eOpt.dataset.source.length;
      if (len < 1) return;
      if (this.current < len - 1) {
        this.current = this.current + 1;
      } else {
        this.current = 0;
      }
      //@ts-ignore
      this.$refs["zchart"].dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: this.current
      })
    }, 3000)
  }
  beforeUnmount() {
    if (this.timeHandle > 0) {
      clearInterval(this.timeHandle)
    }
  }
}
