<style lang="less" scoped>
.center {
  height:360px;
  .ring-con{
  .ring {
    margin-top: 45px;
    margin-left: 55px;
    width: 360px;
    height: 360px;
  }}

  .data {
    margin-top: -305px;
    margin-left: 110px;
    width: 250px;
    height: 250px;

    .title {
      padding-top: 20px;
      color: #ffffff;
      font-size: 36px;
      font-weight: bold;
      line-height: 54px;
    }

    .money {
      color: #ffc000;
      font-size: 64px;
      line-height: 96px;
      font-weight: bold;
    }

    .unit {
      font-size: 24px;
      line-height: 36px;
      color: #ffc000;
    }
  }
}
</style>
<template>
  <div class="center">
    <div class="ring-con">
     <z-echarts :option="eOpt" class="ring"></z-echarts>
    </div>
    <div class="data">
      <div class="title">园区总产值</div>
      <div class="money">{{ dt.total }}</div>
      <div class="unit">（万元）</div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component,VueComponentBase} from "vue3-component-base";
import ZEcharts from "@/components/core/zechart.vue";
import {EChartsOption,graphic } from "echarts";
import ds from "@/core/data"
@Component({name:'CenterCenter',components:{ZEcharts}})
export default class CenterCenter extends  VueComponentBase{
  dt=ds.parkCenterCenter();
  eOpt: EChartsOption = {
    series: [
      {
        color:[new graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(147, 207, 80)'
        }, {
          offset: 1,
          color: 'rgba(169, 178, 72)'
        }]),"#002060"],
        labelLine:undefined,
        type: "pie",
        radius: ['71%', '95%'],
        data: this.dt.percent
      }
    ]
  }
}
</script>
