
import {Component,VueComponentBase} from "vue3-component-base";
import ZEcharts from "@/components/core/zechart.vue";
import {EChartsOption,graphic } from "echarts";
import ds from "@/core/data"
@Component({name:'CenterCenter',components:{ZEcharts}})
export default class CenterCenter extends  VueComponentBase{
  dt=ds.parkCenterCenter();
  eOpt: EChartsOption = {
    series: [
      {
        color:[new graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(147, 207, 80)'
        }, {
          offset: 1,
          color: 'rgba(169, 178, 72)'
        }]),"#002060"],
        labelLine:undefined,
        type: "pie",
        radius: ['71%', '95%'],
        data: this.dt.percent
      }
    ]
  }
}
