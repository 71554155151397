<style lang="less" scoped>
.main-con {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  top: 0;
  left: 0;
  position: fixed;
  transform-origin: left top;

  background-size: cover;
  width:100vw;
  height: 100vh;
  .con {
    background-image: url("../assets/images/bg.png");
    width: 1920px;
    height: 1080px;
  }

  .test {
    background: #3f9714;
    width: 1920px;
    height: 1080px;
  }
}
</style>
<template>
  <div class="main-con" ref="main_con" :style="transformStyle">
    <zswiper class="con" :opt="swpOpt" ref="main_swp">
      <park-data></park-data>
    </zswiper>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted} from 'vue';
import {Component, Inreactive, VueComponentBase} from "vue3-component-base";
import LightButton from "../components/core/lightButton.vue";
import ParkData from "@/components/ParkData.vue";
import EntData from "@/components/EntData.vue";
import SoonBoxVideo from "@/components/Info.vue";
import "swiper/swiper-bundle.css";
import SwipeCore, {EffectCube, SwiperOptions} from "swiper";
//@ts-ignore
import {Swiper, SwiperSlide} from "swiper/vue";
import Zswiper from "@/components/core/zswiper.vue";
import Honur from "@/components/Honour.vue";
import Info from "@/components/Info.vue";
import {addListener, removeListener} from "resize-detector";

SwipeCore.use([EffectCube])
const opt: SwiperOptions = {
  effect: "cube",
  direction: "horizontal",
  loop: false,
  keyboard: true,
  mousewheel: true,
  cubeEffect: {
    slideShadows: true,
    shadow: true,
    shadowOffset: 100,
    shadowScale: 0.6
  }
}
@Component({
  name: "Home",
  components: {
    ParkData,
    EntData,
    Info,
    Honur,
    Zswiper,
  }
})
export default class Home extends VueComponentBase {
  swpOpt = opt;
  transformStyle = {transform: "scale(1,1)"};
  images = []

  mounted() {
    this.resize()
    //@ts-ignore
    addListener(this.$refs["main_con"], () => {
      this.resize()
    })
  }
    resize(){
      this.transformStyle = {
        transform: "scale(" + window.innerWidth / 1920 + "," + window.innerHeight / 1080 + ")"
      }
    }
  beforeUnmount() {
    //@ts-ignore
    if (this.resize) {
      //@ts-ignore
      removeListener(this.$refs["main_con"])
    }
  }
}
</script>
