
import {defineComponent} from "vue";
import PageContainer from "@/components/core/pageContainer.vue";
import HornorCard from "@/components/hornor/HornorCard.vue";

export default defineComponent({
  name: "honur",
  data() {
    return {}
  },
  components: {HornorCard, PageContainer},
  methods: {}
})
