
import {defineComponent} from "vue";
import ZEcharts from "@/components/core/zechart.vue";
import {EChartsOption,graphic} from "echarts";
import {Component, VueComponentBase} from "vue3-component-base";
import ds from "@/core/data";

@Component({
  name: 'LeftTop',
  components: {ZEcharts}
})
export default class LeftTop extends VueComponentBase {
  tmpData=ds.parkLeftTop();
  eOpt: EChartsOption = {
    color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    grid: {
      left:0,
      top:0,
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: this.tmpData.month
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: '销售额',
        type: 'line',
        stack: '总量',
        smooth: true,
        lineStyle: {
          width: 0
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgba(128, 255, 165)'
          }, {
            offset: 1,
            color: 'rgba(1, 191, 236)'
          }])
        },
        emphasis: {
          focus: 'series'
        },
        data: this.tmpData.money
      },
      {
        name: '纳税额',
        type: 'line',
        stack: '总量',
        smooth: true,
        lineStyle: {
          width: 0
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgba(0, 221, 255)'
          }, {
            offset: 1,
            color: 'rgba(77, 119, 255)'
          }])
        },
        emphasis: {
          focus: 'series'
        },
        data:this.tmpData.tax
      }
    ]
  };
  timeHandle=0;
  current=0;
  mounted() {
    this.timeHandle = setInterval(() => {
      //@ts-ignore
      let len = this.eOpt.series[0].data.length;
      if (len < 1) return;
      if (this.current < len - 1) {
        this.current = this.current + 1;
      } else {
        this.current = 0;
      }
      //@ts-ignore
      this.$refs["zchart"].dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: this.current
      })
    }, 3000)
  }
  beforeUnmount() {
    if (this.timeHandle > 0) {
      clearInterval(this.timeHandle)
    }
  }
}
