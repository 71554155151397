
import {Component, VueComponentBase} from "vue3-component-base";
import ZEcharts from "@/components/core/zechart.vue";
import {EChartsOption} from "echarts";
import ds from "@/core/data"
@Component({
  name: "LeftCenter",
  components: {ZEcharts}
})
export default class LeftCenter extends VueComponentBase {
  srv=ds.parkLeftCenter();
  eOpt: EChartsOption = {
    series: [
      {
        color:["#1890ff","#002060"],
        labelLine:undefined,
        type: "pie",
        radius: ['65%', '95%'],
        data: this.srv.percent
      }
    ]
  }
}
