

import {defineComponent} from "vue";
import {Component,VueComponentBase,Prop,Watch} from "vue3-component-base";

@Component({
  name:"app",
})
export default class App extends VueComponentBase{

}

