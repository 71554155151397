<style lang="less" scoped>
.ent-con {
  .left {
    margin-left: 20px;
    width: 400px;
    height: 800px;
    color: #ffffff;

    .title {
      width: 400px;
      height: 40px;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
      background: url("../assets/images/ent-bar.png");
      background-size: cover;

      span {
        padding-left: 5px;
        line-height: 36px;
      }
    }

    .con {
      width: 400px;
      margin-top: 10px;
      height: 800px;
      overflow: hidden;

      .rows {
        .row {
          background: #033052;
          border-radius: 5px;
          margin-top: 10px;
          margin-bottom: 10px;
          height: 70px;
          line-height: 70px;
          width: 400px;

          .name {
            font-size: 24px;
            opacity: 1;
            text-align: center;
            width: 220px;
          }

          .money {
            font-size: 24px;
            width: 120px;
            color: #ffc000;
          }

          .rate {
            width: 60px;
            font-size: 16px;
            color: #3f9714;
          }
        }

        .check {
          background: #036296;
        }
      }
    }
  }

  .right {
    margin-left: 30px;
    margin-top: 40px;
    width: 1365px;
    height: 805px;
    background-image: url("../assets/images/ent-rect.png");
    background-size: cover;

    .ball {
      background: url("../assets/images/ent-ball.png");
      background-size: cover;
      width: 558px;
      height: 558px;
      position: absolute;
      top: 275px;
      left: 800px;
    }

    .card0 {
      position: absolute;
      top: 250px;
      left: 600px;
    }

    .card1 {
      position: absolute;
      top: 250px;
      left: 1258px;
    }

    .card2 {
      position: absolute;
      left: 600px;
      top: 720px;
    }

    .card3 {
      position: absolute;
      top: 720px;
      left: 1258px;
    }
  }
}
</style>
<template>
  <page-container title="企业数据">
    <div class="ent-con zfx zfx-row">
      <div class="left">
        <div class="title"><span>入驻企业</span></div>
        <div class="con">
          <div class="rows">
            <div v-for="(row,index) in rows"
                 class="zfx zfx-row zfx-jc-stretch zfx-ai-stretch"
                 :class="{'row':true,'odd':index%2===0,'even':index%2==1,check:index===curIndex}"
                 @click="changeIdx(index)">
              <span class="name">{{ row.name }}</span>
              <span class="money">{{ row.sale.num }}</span>
              <span class="rate">{{ row.sale.rate }}%</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="ball"></div>
        <ent-card class="card0" name="销售总额" :rate="cRow.sale.rate" :val="cRow.sale.num"></ent-card>
        <ent-card class="card1" name="纳税总额" :rate="cRow.tax.rate" :val="cRow.tax.num"></ent-card>
        <ent-card class="card2" name="就业人数" :rate="cRow.worker.rate" :val="cRow.worker.num"></ent-card>
        <ent-card class="card3" name="客户数量" :rate="cRow.custom.rate" :val="cRow.custom.num"></ent-card>
      </div>

    </div>
  </page-container>
</template>

<script lang="ts">
import {Component, VueComponentBase} from "vue3-component-base";
import ds from "@/core/data"
import PageContainer from "@/components/core/pageContainer.vue";
import {SwiperOptions} from "swiper";
import EntCard from "@/components/core/entCard.vue";
const dRow=ds.entList();
@Component({
  name: "EntData",
  components: {EntCard, PageContainer}
})
export default class EntData extends VueComponentBase {
  rows=dRow
  curIndex= 0

   get cRow(){
    console.warn(this.rows.length)
    return this.rows[this.curIndex];
  }

  changeIdx(idx: number) {
    this.curIndex = idx;
  }

}
</script>
