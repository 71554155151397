<style lang="less" scoped>
.box{
  position: relative;
  width: 240px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
  transition: 0.5s;
  background: #083868;
  float: left;
  .imgBox{
    position: absolute;
    top: 0;
    left: 0;
    width: 240px;
    height:160px;
    box-sizing: border-box;
    img{
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      opacity: 0.85;
      transition: 0.5s;
    }
  }
  .content{
    position: absolute;
    left: 5px;
    right: 5px;
    height: 100px;
    background: rgba(0,0,0,0.4);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    /*设置div盒子元素的背景透明度越靠近1越不透明*/
    transition: 0.5s;
    /*向下伸长实现效果时间*/
    bottom: 5px;
    .title{
      font-size: 24px;
      color: #FFFFFF;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 1px;
    }
    .desc{
      margin-top: 10px;
      font-size: 16px;
      color: #FFFFFF;
      font-weight: 200;
      line-height: 24px;
    }
  }
}
.box:hover{
  height: 270px;
  .content{
    opacity: 1;

  }
  .imgBox img{
    opacity: 1;
  }
}
.box:before{
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  background: #FFFFFF;
  z-index:-1;
}
.box:after{
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  background: #FFFFFF;
  z-index:-2;
  filter: blur(40px);

}
.box:before,.box:after{
  background:linear-gradient(235deg,#772aff,#010615,#2196fc);
}


</style>
<template>
  <div class="box">
    <div class="imgBox">
      <img :src="image" alt="">
    </div>
    <div class="content">
      <div class="title">{{title}}</div>
      <div class="desc">{{ desc }}</div>
    </div>
  </div>

</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "HornorCard",
  props: {
    title: {type: String, required: true},
    image: {type: String, required: true},
    desc: {type: String, required: true}
  },
  data() {
    return {}
  },
  components: {},
  methods: {}
})
</script>
