<style lang="less" scoped>
.con {
  margin-left: 150px;

  .title {
    background-image: url("../../assets/images/park-left-bottom-bar.png");
    width: 531px;
    height: 40px;
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    background-size: cover;

    span {
      margin-top: -10px;
      margin-left: 10px;
      line-height: 36px;
    }
  }

  .chart {
    width: 560px;
    .chart-con {
      margin-top:-40px;
      margin-left: -20px;
      width:560px;
      height:280px;
    }
  }
}
</style>
<template>
  <div class="con">
    <div class="title"><span>企业年达成率</span></div>
    <div class="chart">
      <z-echarts ref="zchart" :ShowTime="3000" :option="eOpt" class="chart-con"></z-echarts>
    </div>
  </div>
</template>

<script lang="ts">
import ZEcharts from "@/components/core/zechart.vue";
import {EChartsOption,graphic} from "echarts";
import {Component, VueComponentBase} from "vue3-component-base";
import ds from "@/core/data"
@Component({
  name: 'RightBottom',
  components: {ZEcharts}
})
export default class RightBottom extends VueComponentBase {
  eData=ds.parkRightBottom();
  eOpt: EChartsOption = {
    width:'100%',
    height:'100%',
    tooltip:{},
    xAxis: {
      data: this.eData.ent,
    },
    yAxis: {

      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
      }
    },
    dataZoom: [
      {
        type: 'inside'
      }
    ],
    series: [
      {
        type: 'bar',
        showBackground: true,
        data: this.eData.rate,
        barWidth:20,
      }
    ]
  }
  timeHandle=0;
  current=0;
  mounted() {
    this.timeHandle = setInterval(() => {
      //@ts-ignore
      let len = this.eOpt.series[0].data.length;
      if (len < 1) return;
      if (this.current < len - 1) {
        this.current = this.current + 1;
      } else {
        this.current = 0;
      }
      //@ts-ignore
      this.$refs["zchart"].dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: this.current
      })
    }, 3000)
  }
  beforeUnmount() {
    if (this.timeHandle > 0) {
      clearInterval(this.timeHandle)
    }
  }
}
</script>
