<style lang="less" scoped>
  .content{
    width: 1920px;
    height: 907px;
    background: url("../assets/images/diven.png");
    background-size: cover;
  .left {
    width: 725px;
    height: 907px;
    .title {
      color: #ffffff;
      font-size: 28px;
      font-weight: 700;
      width: 200px;
      height: 36px;
      margin-left: 200px;
    }
    }

  .center {
    width: 470px;
    height: 907px;
  }

  .right {
    width: 725px;
    height: 907px;
    .title {
      color: #ffffff;
      font-size: 28px;
      font-weight: 700;
      width: 200px;
      height: 36px;
      margin-left: 340px;
    }
  }
  }

</style>
<template>
  <page-container title="园区数据">
      <div class="content zfx zfx-row">
        <div class="left zfx zfx-col">
          <div class="title">
            产 值 数 据
          </div>
          <left-top></left-top>
          <left-center></left-center>
          <left-bottom></left-bottom>
        </div>
        <div class="center zfx zfx-col">
          <center-top></center-top>
          <center-center></center-center>
          <center-bottom/>
        </div>
        <div class="right zfx zfx-col">
          <div class="title">
            产 业 分 布
          </div>
          <right-top/>
          <right-center></right-center>
          <right-bottom/>
        </div>
      </div>
  </page-container>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import LeftTop from "@/components/park/LeftTop.vue";
import LeftCenter from "@/components/park/LeftCenter.vue";
import LeftBottom from "@/components/park/LeftBottom.vue";
import CenterTop from "@/components/park/CenterTop.vue";
import CenterCenter from "@/components/park/CenterCenter.vue";
import CenterBottom from "@/components/park/CenterBottom.vue";
import RightTop from "@/components/park/RightTop.vue";
import RightCenter from "@/components/park/RightCenter.vue";
import RightBottom from "@/components/park/RightBottom.vue";
import ZTitle from "@/components/core/ztitile.vue";
import PageContainer from "@/components/core/pageContainer.vue";

export default defineComponent({
  name: "ParkData",
  data() {
    return {}
  },
  components: {
    PageContainer,
    ZTitle,
    RightBottom,
    RightCenter, RightTop, CenterBottom, CenterCenter, CenterTop, LeftBottom, LeftCenter, LeftTop},
  methods: {}
})
</script>
