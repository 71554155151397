
import {defineComponent} from "vue";
import ds from "@/core/data"
const dt=ds.parkCenterTop();
export default defineComponent({
name: "CenterTop",
data(){
return {
  total:dt
}},
components:{},
methods:{}
})
