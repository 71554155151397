<style lang="less" scoped>
 .vid{
   margin-top: 20px;
   border: 1px #001BA7 solid;
   box-shadow: 0px 0px 50px 10px #3f51a7;
 }
</style>
<template>
  <page-container title="园区简介">
    <div class="zw-full zh-full zfx zfx-row  zfx-jc-center">
        <video class="vid" controls  height="864" width="1536">
          <source src="http://bi.ok56.com.cn/six.mp4" type="video/mp4">
        </video>
    </div>
  </page-container>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import PageContainer from "@/components/core/pageContainer.vue";
export default defineComponent({
name: "Info",
data(){
return {

}},
components:{
  PageContainer
},
methods:{}
})
</script>
