<style lang="less" scoped>
.con {
  margin-left: 30px;

  .title {
    background-image: url("../../assets/images/park-left-bottom-bar.png");
    width: 531px;
    height: 40px;
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    background-size: cover;

    span {
      margin-top: -10px;
      margin-left: 10px;
      line-height: 36px;
    }
  }

  .chart {
    width: 540px;
    .chart-con {
      margin-top: 10px;
      width:540px;
      height:250px;
    }
  }
}
</style>
<template>
  <div class="con">
    <div class="title"><span>纳税TOP10</span></div>
    <div class="chart">
      <z-echarts ref="zchart" :option="eOpt" class="chart-con"></z-echarts>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, VueComponentBase} from "vue3-component-base";
import ZEcharts from "@/components/core/zechart.vue";
import {EChartsOption} from "echarts";
import ds from "@/core/data"
@Component({name: "LeftBottom", components: {ZEcharts}})
export default class LeftBottom extends VueComponentBase {
  eOpt: EChartsOption = {
    tooltip:{},
    series: [
      {
        name: '纳税排行榜',
        type: 'treemap',
        width:'100%',
        height:'100%',
        itemStyle: {
          borderColor: '#fff'
        },
        breadcrumb:undefined,
        data: ds.parkLeftBottom()
      }
    ]
  }
  timeHandle=0;
  current=0;
  mounted() {
    this.timeHandle = setInterval(() => {
      //@ts-ignore
      let len = this.eOpt.series[0].data.length;
      if (len < 1) return;
      if (this.current < len - 1) {
        this.current = this.current + 1;
      } else {
        this.current = 0;
      }
      //@ts-ignore
      this.$refs["zchart"].dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: this.current
      })
    }, 3000)
  }
  beforeUnmount() {
    if (this.timeHandle > 0) {
      clearInterval(this.timeHandle)
    }
  }
}
</script>
