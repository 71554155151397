
import {defineComponent} from "vue";

export default defineComponent({
  name: "HornorCard",
  props: {
    title: {type: String, required: true},
    image: {type: String, required: true},
    desc: {type: String, required: true}
  },
  data() {
    return {}
  },
  components: {},
  methods: {}
})
