function rgb(data:string){
    let str="rgba(";
    if(data.startsWith("#")) data=data.slice(1,data.length-1);
    for(let i=0;i<8-data.length;i++) data+="0";
    str+=parseInt("0x"+data.slice(0,2))+","
    str+=parseInt("0x"+data.slice(2,4))+","
    str+=parseInt("0x"+data.slice(4,6))+","
    str+=(parseInt("0x"+data.slice(6,8))/255.0).toFixed(2)+")"
    return str;
}
const axisConfig={
    label:{
        style:{
            fill:rgb("#3D76DD99"),
            fontSize:16,
        }
    },
    line:{
        style:{
            stroke: rgb("#3D76DD99")
        }
    },
    tickLine:{
        style:{
            stroke: rgb("#3D76DD99")
        }
    },
    grid: {
        line: {
            style:{
                lineWidth:1,
                stroke: rgb("#3D76DD33")
            }
        }
    }
}
export default {
    geometries: {},
    components: {
        axis: {
            bottom:axisConfig,
            left:axisConfig,


        },
        tooltip:{
            domStyles:{
                "g2-tooltip":{
                    background:rgb("#001D70ee"),
                    boxShadow:"0px 0px 10px "+rgb("#9AC5FFcc"),
                    color:rgb("#B8E1FFcc"),
                    fontSize:16
                }
            }
        }

    }
}
