
import {Component, VueComponentBase} from "vue3-component-base";
import ZEcharts from "@/components/core/zechart.vue";
import {EChartsOption} from "echarts";
import ds from "@/core/data"
@Component({
  name: "RightCenter",
  components: {ZEcharts}
})
export default class RightCenter extends VueComponentBase {
  eData=ds.parkRightCenter();
  eOpt: EChartsOption = {
    series: [
      {
        color:["#5ad8a6","#002060"],
        labelLine:undefined,
        type: "pie",
        radius: ['65%', '95%'],
        data: this.eData.percent
      }
    ]
  }
}
