<style lang="less" scoped>
.title {
  margin-top: 20px;
  margin-bottom: 9px;
  width: 805px;
  height: 94px;
  background-size: cover;
  background-image: url("../../assets/images/park-title.png");

  .btn {
    width: 805px;
    height: 94px;

  }
}
</style>
<template>
  <div class="title" @click="switchFull">
    <a href="#" @click="switchFull" class="btn"></a>
  </div>
</template>

<script lang="ts">
import {Component, VueComponentBase} from "vue3-component-base";

@Component({name: "ZTitle"})
export default class ZTitle extends VueComponentBase {

  full = false

  switchFull() {
    let ele = document.body;
    if (this.full) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        //@ts-ignore
      } else if (document.mozCancelFullScreen) {
        //@ts-ignore
        document.mozCancelFullScreen();
        //@ts-ignore
      } else if (document.webkitExitFullscreen) {
        //@ts-ignore
        document.webkitExitFullscreen();
        //@ts-ignore
      } else if (document.msExitFullscreen) {
        //@ts-ignore
        document.msExitFullscreen();
      }
      this.full=false;
    } else {
      if (ele.requestFullscreen) {
        ele.requestFullscreen();
        //@ts-ignore
      } else if (ele.mozRequestFullScreen) {
        //@ts-ignore
        ele.mozRequestFullScreen();
        //@ts-ignore
      } else if (ele.webkitRequestFullscreen) {
        //@ts-ignore
        ele.webkitRequestFullscreen();
        //@ts-ignore
      } else if (ele.msRequestFullscreen) {
        //@ts-ignore
        ele.msRequestFullscreen();
      }
      this.full=true;
    }
  }
}
</script>
