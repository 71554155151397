<style lang="less" scoped>
</style>
<template>
  <div class="z-swiper" ref="swipcon">
    <div class="swiper-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import Swiper, {SwiperOptions} from "swiper";
export default defineComponent({
  name: "zswiper",
  props:{
    opt:{type:Object,required:true}
  },
  setup(prop,context){
    let swipcon=ref(null)
    let swiper=ref(null);
    onMounted(()=>{
      //@ts-ignore
     swiper= new Swiper(swipcon.value as HTMLElement,prop.opt)
    })
   let toPage=(i:number)=>{
      //@ts-ignore
      if(swiper) (swiper as Swiper).slideTo(i);
    }
    return {
      //@ts-ignore
      swiper,toPage,swipcon
    }
  }
})
</script>
