
import {Component, Prop, VueComponentBase} from "vue3-component-base";

@Component({name: "PageContainer"})
export default class PageContainer extends VueComponentBase {
  @Prop({type: String, default: "园区数据", required: true})
  title: string
  full = false

  switchFull() {
    let ele = document.body;
    if (this.full) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        //@ts-ignore
      } else if (document.mozCancelFullScreen) {
        //@ts-ignore
        document.mozCancelFullScreen();
        //@ts-ignore
      } else if (document.webkitExitFullscreen) {
        //@ts-ignore
        document.webkitExitFullscreen();
        //@ts-ignore
      } else if (document.msExitFullscreen) {
        //@ts-ignore
        document.msExitFullscreen();
      }
      this.full = false;
    } else {
      if (ele.requestFullscreen) {
        ele.requestFullscreen();
        //@ts-ignore
      } else if (ele.mozRequestFullScreen) {
        //@ts-ignore
        ele.mozRequestFullScreen();
        //@ts-ignore
      } else if (ele.webkitRequestFullscreen) {
        //@ts-ignore
        ele.webkitRequestFullscreen();
        //@ts-ignore
      } else if (ele.msRequestFullscreen) {
        //@ts-ignore
        ele.msRequestFullscreen();
      }
      this.full = true;
    }
  }

}
