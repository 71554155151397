
import {Component,Prop,VueComponentBase} from "vue3-component-base";
@Component({name:"entCard"})
export default class EntCard extends VueComponentBase{
 @Prop({type:String,required:true})
 name:string
  @Prop({type:Number,required:true})
  val:number
  @Prop({type:Number,required:true})
  rate:number
}
