
import {defineComponent} from "vue";
import PageContainer from "@/components/core/pageContainer.vue";
export default defineComponent({
name: "Info",
data(){
return {

}},
components:{
  PageContainer
},
methods:{}
})
