<style lang="less" scoped>
.page-con {
  width: 1920px;
  height: 1080px;
  background: url("../../assets/images/bg.png") center;

  .header {
    width: 1920px;
    height: 128px;

    .title-con {
      width: 805px;
      height: 128px;
      padding-top: 22px;

      .bg {
        width: 805px;
        height: 70px;
        background: url("../../assets/images/title.png") center;
        background-size: 805px 70px;
      }

      .tit {
        height: 30px;
        line-height: 30px;
        font-size: 36px;
        font-weight: 500;
        color: #228fc8;
        margin-top: -8px;
        text-shadow: 2px 2px #001D70;
      }
    }
  }

  .content {
    width: 1920px;
    height: 952px;
  }
  #canvas {
    width: 100%;
    height: 100%;
    display: block;
    opacity: .8;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom: 0;
  }
}
</style>
<template>
  <div class="page-con swiper-slide">
    <div class="header zfx zfx-row zfx-jc-center" >
      <div class="title-con zfx zfx-col " @click="switchFull">
        <div class="bg"></div>
        <div class="tit">{{ title }}</div>
      </div>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, VueComponentBase} from "vue3-component-base";

@Component({name: "PageContainer"})
export default class PageContainer extends VueComponentBase {
  @Prop({type: String, default: "园区数据", required: true})
  title: string
  full = false

  switchFull() {
    let ele = document.body;
    if (this.full) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        //@ts-ignore
      } else if (document.mozCancelFullScreen) {
        //@ts-ignore
        document.mozCancelFullScreen();
        //@ts-ignore
      } else if (document.webkitExitFullscreen) {
        //@ts-ignore
        document.webkitExitFullscreen();
        //@ts-ignore
      } else if (document.msExitFullscreen) {
        //@ts-ignore
        document.msExitFullscreen();
      }
      this.full = false;
    } else {
      if (ele.requestFullscreen) {
        ele.requestFullscreen();
        //@ts-ignore
      } else if (ele.mozRequestFullScreen) {
        //@ts-ignore
        ele.mozRequestFullScreen();
        //@ts-ignore
      } else if (ele.webkitRequestFullscreen) {
        //@ts-ignore
        ele.webkitRequestFullscreen();
        //@ts-ignore
      } else if (ele.msRequestFullscreen) {
        //@ts-ignore
        ele.msRequestFullscreen();
      }
      this.full = true;
    }
  }

}
</script>
