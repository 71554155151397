class EntInfo {
    constructor(public ent: string, public number, public tax: number, public worker: number, public custom: number) {
    }
}

class MonthMoney {
    constructor(public month: number, public money: number) {
    }
}

class MoneyData {
    public name: string
    public money: number
    public tax: number
    public worker: number
    public custom: number
}
class NameValue{
    constructor(public name:string,public value:number) {
    }
}
class NumRate{
    constructor(public num:number,public rate:number) {
    }
}
export class EntDataItem{
    constructor(public name:string, public sale:NumRate,public tax:NumRate,public worker:NumRate,public custom:NumRate) {
    }
}
class DataService {
    public ents = [
        new EntInfo("众合共䊨", 0.142, 0.091, 26, 0),
        new EntInfo("方达龙腾", 0.123, 0.082, 26, 0),
        new EntInfo("心里程", 0.114, 0.053, 32, 0),
        new EntInfo("新罗产业基金", 0.096, 0.032, 28, 0),
        new EntInfo("南威软件", 0.085, 0.065, 26, 0),
        new EntInfo("华为科技", 0.078, 0.071, 29, 0),
        new EntInfo("淘一淘", 0.066, 0.072, 27, 0),
        new EntInfo("中软国际", 0.059, 0.062, 22, 0),
        new EntInfo("航天科技", 0.048, 0.068, 24, 0),
        new EntInfo("中报科技", 0.037, 0.043, 20, 0),
        new EntInfo("南工科技", 0.026, 0.059, 21, 0),
        new EntInfo("航天科技", 0.022, 0.064, 16, 0),
        new EntInfo("猪八戒", 0.019, 0.066, 17, 0),
        new EntInfo("天御网络", 0.017, 0.067, 14, 0),
        new EntInfo("维尔酷科技", 0.015, 0.062, 14, 0),
        new EntInfo("炽热网络", 0.013, 0.061, 16, 0),
        new EntInfo("汇辰电气", 0.012, 0.062, 15, 0),
        new EntInfo("恒扬网络", 0.011, 0.063, 13, 0),
        new EntInfo("金账房财务", 0.009, 0.065, 12, 0),
        new EntInfo("宝利鑫科技", 0.008, 0.064, 12, 0),
        new EntInfo("心里程教育", 0.001, 0.072, 26, 0),
    ]
    public historyMoney = [
        new MonthMoney(202006, 16232485.36),
        new MonthMoney(202007, 22312347.23),//
        new MonthMoney(202008, 64381285.68),
        new MonthMoney(202009, 87642574.7),
        new MonthMoney(202010, 103282685.5),
        new MonthMoney(202011,  117252484.4),
        new MonthMoney(202012, 138642864.2),
        new MonthMoney(202101,  158391586.5),
        new MonthMoney(202102, 203681384.4),
        new MonthMoney(202103, 222458158.4),
        new MonthMoney(202104,  242586847.6),
        new MonthMoney(202105, 261254816.5),
        new MonthMoney(202106, 307433962.8),
        new MonthMoney(202107, 321586415.6),
        new MonthMoney(202108, 331586415.6),
        new MonthMoney(202109, 321586415.6),
        new MonthMoney(202110, 355864157.6),
        new MonthMoney(202111, 367481582.5),
        new MonthMoney(202112, 375763415.6),
        new MonthMoney(202201, 352684823.6),
    ]
    ///人才分布
    public workerRate = [0.005, 0.01, 0.015, 0.62, 0.24, 0.105, 0.05]
    public rateRange = [0.02, 0.20];
    public taxRate = 0.07439
    ///客单价
    public customPrice=12285;
    ///左侧上
    public parkLeftTop() {
        let month: number[] = [];
        let money: number[] = [];
        let tax: number[] = [];
        this.historyMoney.map((v, i, k) => {
            month.push(v.month);
            money.push(this.toInt(v.money / 10000))
            tax.push(this.toInt(v.money / 10000 * this.taxRate));
        })
        let per=this.getMonthPercent();
        money[money.length-1]=this.toInt(money[money.length-1]*per);
        tax[tax.length-1]=this.toInt(tax[tax.length-1]*per);
        return {month, money, tax}
    }
    ///左侧中
    public parkLeftCenter() {
        let percent=this.getMonthPercent();
        let last=1-percent;
        let his=this.historyMoney[this.historyMoney.length-1];
        return {
            percent:[this.fix(percent*100,1),this.fix(last*100,1)],
            sale:new NumRate(this.toInt(his.money*percent/10000),this.randomIncreaseRate()),
            tax:new NumRate(this.toInt(his.money*percent/10000*this.taxRate),this.randomIncreaseRate()),
            worker:new NumRate(this.totalWorker(),this.randomIncreaseRate()),
            custom:new NumRate(this.toInt(his.money*percent/this.customPrice),this.randomIncreaseRate())
        }
    }
    public parkLeftBottom(){
        let result:NameValue[]=[]
        let totalMoney=this.getTotalSaleMoney();
        for(let i=0;i<10;i++){
            let ent=this.ents[i];
            result.push(new NameValue(ent.ent,this.fix(ent.tax*totalMoney/10000,2)))
        }
        return result;
    }
    public parkCenterTop(){
        let total=this.getTotalSaleMoney()
        return {
            sale:new NumRate(this.toInt(total/10000),this.randomIncreaseRate()),
            tax:new NumRate(this.toInt(total/10000*this.taxRate),this.randomIncreaseRate()),
            worker:new NumRate(this.totalWorker(),this.randomIncreaseRate()),
            custom:new NumRate(this.toInt(total/this.customPrice),this.randomIncreaseRate()),
        }
    }
    public parkCenterCenter(){
        let total=this.getTotalSaleMoney();
        return {
            percent:[0.75,0.25],
            total:this.toInt(total/10000)
        }
    }
    public parkRightTop(){
        let worker=this.totalWorker();
        return [
            new NameValue("小学",this.toInt(worker*this.workerRate[0])),
            new NameValue("初中",this.toInt(worker*this.workerRate[1])),
            new NameValue("高中",this.toInt(worker*this.workerRate[2])),
            new NameValue("大专",this.toInt(worker*this.workerRate[3])),
            new NameValue("本科",this.toInt(worker*this.workerRate[4])),
            new NameValue("硕士",this.toInt(worker*this.workerRate[5])),
            new NameValue("博士",this.toInt(worker*this.workerRate[6])),]
    }
    public parkRightCenter(){
        let now=(new Date()).getFullYear()*100;
        let totalMoney=0;
        for(let x of this.historyMoney){
            if(x.month<now) continue;
            totalMoney+=x.money;
        }
        totalMoney=totalMoney-this.historyMoney[this.historyMoney.length-1].money*(1-this.getMonthPercent());
        let year=this.getYearPercent();
        return {
            percent:[this.fix(year*100,1),this.fix((1-year)*100,1)],
            sale:new NumRate(this.toInt(totalMoney/10000),this.randomIncreaseRate()),
            tax:new NumRate(this.toInt(totalMoney/10000*this.taxRate),this.randomIncreaseRate()),
            worker:new NumRate(this.totalWorker(),this.randomIncreaseRate()),
            custom:new NumRate(this.toInt(totalMoney/this.customPrice),this.randomIncreaseRate())
        }

    }
    public parkRightBottom(){
        let year=this.getYearPercent();
        let name:string[]=[]
        let result:number[]=[];
        for(let i=0;i<10;i++){
            name.push(this.ents[i].ent)
            result.push(this.toInt((year*100*(0.75+Math.random()/3))));
        }
        return {
            ent:name,
            rate:result
        }
    }
    public entList(){
        let total=this.getTotalSaleMoney()/10000;
        let result:EntDataItem[]=[];
        for(let e of this.ents){
            let ent=new EntDataItem(e.ent,
                new NumRate(this.toInt(total*e.number),this.randomIncreaseRate()),
                new NumRate(this.toInt(total*e.number*e.tax),this.randomIncreaseRate()),
                new NumRate(e.worker,this.randomIncreaseRate()),
                new NumRate(this.toInt(total*10000/this.customPrice),this.randomIncreaseRate()),
                )
            result.push(ent)
        }
        return result;
    }
    /**
     * 获取总销售额
     * @private
     */
    private getTotalSaleMoney(){
        let m=0;
        for(let i=0;i<this.historyMoney.length-1;i++) m+=this.historyMoney[i].money
        m+=this.historyMoney[this.historyMoney.length-1].money*this.getMonthPercent();
        return m;
    }
    private totalWorker(){
        let w=0;
        for(let x of this.ents){
            w+=x.worker;
        }
        return w;
    }
    private randomIncreaseRate(){
       return this.fix((0.02+ Math.random()/5)*100,2);
    }
    private getMonthPercent() {
        let d = new Date();
        let has = d.getDate() * 1.0;
        d.setMonth(d.getMonth() + 1);
        d.setDate(0);
        return has / d.getDate()
    }

    private getYearPercent() {
        let now=new Date();
        let start=new Date(now.getFullYear(),0,1);
        let total=(now.valueOf()-start.valueOf())/(24*60*60*1000);
        return (Math.ceil(total)+1)/365;
    }
    private toInt(value:number){
        return parseInt(value.toString());
    }
    private fix(value: number, n: number) {
        return Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
    }
}
const srv=new DataService();
export default srv;
